<template>
  <div style="position: relative; height: 100%">
    <div class="student-wrap">
      <div class="student-top">
        <div class="student-leftWrap">
          <div class="student-leftWrap-info">
            <img
              src="@/assets/newImage/icon-flag.png"
              class="reseller-img"
              v-if="studentDetail.reseller && studentDetail.reseller !== null"
            />

            <a-popover
              placement="bottom"
              overlayClassName="ambassadorPopover"
              v-if="studentDetail.reseller && studentDetail.reseller !== null"
            >
              <template slot="content">
                <div class="ambassador-item" style="padding-top: 10px">
                  <span class="ambassador-label">大使星级：</span>
                  <span class="ambassador-con">
                    <img
                      v-for="index of studentDetail.reseller.star"
                      :key="index"
                      src="@/assets/Star2.png"
                      class="student-start"
                    />
                    <img
                      v-for="index of 5 - studentDetail.reseller.star"
                      src="@/assets/Star6.png"
                      class="student-start"
                      :key="index + 'star1'"
                    />
                  </span>
                </div>
                <div class="ambassador-item">
                  <span class="ambassador-label">升星待完成订单：</span>
                  <span class="ambassador-con">{{ studentDetail.reseller.counterLess || '-' }}单</span>
                </div>
                <div class="ambassador-item">
                  <span class="ambassador-label">福利官<span style="font-size: 12px">(大使助理)</span>：</span>
                  <span class="ambassador-con">{{ studentDetail.reseller.assistantName || '-' }}</span>
                </div>
                <div class="ambassador-item">
                  <span class="ambassador-label">大使注册时间：</span>
                  <span class="ambassador-con">{{ studentDetail.reseller.whenCreated || '-' }}</span>
                </div>
                <div class="ambassador-item">
                  <span class="ambassador-label">回馈方式：</span>
                  <span class="ambassador-con">{{
                    initOptionsType(studentDetail.reseller.rewardMethod, rewardMethodArr) || '-'
                  }}</span>
                </div>
              </template>
              <div class="student-portrait">
                <img :src="studentDetail.headImageUrl" class="student-portImg" />
              </div>
            </a-popover>
            <div class="student-portrait" v-else>
              <img :src="studentDetail.headImageUrl" class="student-portImg" />
            </div>
            <div class="student-content-info">
              <div class="top-infowrap">
                <div class="top-info">
                  <a-tooltip>
                    <template slot="title">
                      {{ studentDetail.fullName }}
                    </template>
                    <span class="student-name">{{ studentDetail.fullName }}</span>
                  </a-tooltip>
                  <span style="font-weight: normal; font-size: 20px; color: #333; padding-right: 16px"
                    >({{ studentDetail.code }})</span
                  >
                  <span class="line"></span>
                  <a-tooltip>
                    <template slot="title"> 聊天 </template>
                    <img
                      src="@/assets/newImage/chat.png"
                      class="operator-icon"
                      v-show="showChatFlag"
                      @click="hrefChat"
                    />
                  </a-tooltip>
                  <a-tooltip>
                    <template slot="title"> 电话 </template>
                    <img src="@/assets/newImage/phone.png" @click="zoomMobleFun" class="operator-icon" />
                  </a-tooltip>
                  <a-tooltip>
                    <template slot="title"> 邮箱 </template>
                    <img src="@/assets/newImage/email.png" @click="openEmail" class="operator-icon" />
                  </a-tooltip>
                </div>
                <div class="operator-btnWrap">
                  <a-button class="btn-left" @click="openRecordsModal" size="small"> 跟进 </a-button>

                  <!-- <a-button class="operator-btn" @click="addTask"> 新建任务 </a-button> -->
                  <a-popover
                    overlayClassName="classPopover"
                    placement="bottom"
                    v-model:visible="newLeadVisible"
                    v-if="userInfo.roleName !== 'X_WUKONG'"
                  >
                    <template slot="content">
                      <ul class="clue-wrap">
                        <li v-for="(item, index) in clueArr" @click="createClue(item.value)" :key="index">
                          {{ item.label }}
                        </li>
                      </ul>
                    </template>
                    <a-button class="btn-left" size="small">线索</a-button>
                  </a-popover>

                  <a-button class="btn-left" @click="handleSharePoster" size="small"> 分享 </a-button>
                  <a-dropdown>
                    <a-menu slot="overlay" @click="handleMenuClick">
                      <a-menu-item key="addTask"> 新建任务 </a-menu-item>
                      <a-menu-item key="loss" @click="goLostRefundStudent"> 流失记录</a-menu-item>
                
                      <a-sub-menu key="1">
                        <template #title>调班课表</template>
                        <a-menu-item key="CHINESE">中文</a-menu-item>
                        <a-menu-item key="MATH">数学</a-menu-item>
                        <a-menu-item key="ENGLISH">英文</a-menu-item>
                      </a-sub-menu>
                    </a-menu>
                    <a-button class="btn-left" size="small">更多 <a-icon type="down" /> </a-button>
                  </a-dropdown>
                </div>
              </div>
              <div class="bot-infowrap">
                <img src="@/assets/timeicon.png" style="width: 12px; height: 12px" />
                <span class="remaining-title">剩余课时:</span>
                <span class="remaining-text"
                  >中文
                  <span
                    v-if="studentDetail.chineseRemaining"
                    :class="[
                      'remaining-num',
                      studentDetail.chineseRemaining <= 8 && studentDetail.chineseRemaining >= 1 ? 'warning' : '',
                    ]"
                    >{{ studentDetail.chineseRemaining }}</span
                  >
                  <span v-else style="color: rgba(0, 0, 0, 0.85)">无</span>
                  <a-popover>
                    <template slot="content">
                      <div>剩余课时不足，请联系学生续课续费</div>
                    </template>
                    <img
                      v-if="studentDetail.chineseRemaining <= 8 && studentDetail.chineseRemaining >= 1"
                      src="@/assets/newImage/warning.png"
                      style="width: 12px; height: 12px"
                    />
                  </a-popover>
                </span>

                <span class="line mr-10"></span>
                <span class="remaining-text"
                  >数学
                  <span
                    v-if="studentDetail.mathRemaining"
                    :class="[
                      'remaining-num',
                      studentDetail.mathRemaining <= 8 && studentDetail.mathRemaining >= 1 ? 'warning' : '',
                    ]"
                    >{{ studentDetail.mathRemaining }}</span
                  >
                  <span style="color: rgba(0, 0, 0, 0.85)" v-else>无</span>
                  <a-popover>
                    <template slot="content">
                      <div>剩余课时不足，请联系学生续课续费</div>
                    </template>
                    <img
                      v-if="studentDetail.mathRemaining <= 8 && studentDetail.mathRemaining >= 1"
                      src="@/assets/newImage/warning.png"
                      style="width: 12px; height: 12px"
                    />
                  </a-popover>
                </span>
                <span class="line mr-10"></span>
                <span class="remaining-text"
                  >英文
                  <span
                    v-if="studentDetail.englishRemaining"
                    :class="[
                      'remaining-num',
                      studentDetail.englishRemaining <= 8 && studentDetail.englishRemaining >= 1 ? 'warning' : '',
                    ]"
                    >{{ studentDetail.englishRemaining }}</span
                  >
                  <span style="color: rgba(0, 0, 0, 0.85)" v-else>无</span>
                  <a-popover>
                    <template slot="content">
                      <div>剩余课时不足，请联系学生续课续费</div>
                    </template>
                    <img
                      v-if="studentDetail.englishRemaining <= 8 && studentDetail.englishRemaining >= 1"
                      src="@/assets/newImage/warning.png"
                      style="width: 12px; height: 12px"
                    />
                  </a-popover>
                </span>

                <a-popover overlayClassName="classPopover" placement="bottom">
                  <template slot="content">
                    <div class="douItem">
                      悟空豆余额：<span class="douNum">{{
                        studentDetail.studentWuKongBeanStatistics &&
                        studentDetail.studentWuKongBeanStatistics.pointsBalance
                      }}</span>
                      <span style="cursor: pointer; color: #04cb94; padding-left: 40px" @click="hrefWuKongDouDetail"
                        >查看明细</span
                      >
                    </div>
                    <div class="douItem">
                      获得总量：<span class="douNum">{{
                        studentDetail.studentWuKongBeanStatistics &&
                        studentDetail.studentWuKongBeanStatistics.obtainedTotalPoints
                      }}</span>
                    </div>
                    <div class="douItem">
                      消耗总量：<span class="douNum">{{
                        studentDetail.studentWuKongBeanStatistics &&
                        studentDetail.studentWuKongBeanStatistics.consumedTotalPoints
                      }}</span>
                    </div>
                    <div class="douItem">
                      过期总量：<span class="douNum">{{
                        studentDetail.studentWuKongBeanStatistics &&
                        studentDetail.studentWuKongBeanStatistics.expiredTotalPoints
                      }}</span>
                    </div>
                  </template>
                  <span
                    >悟空豆余额：<span style="color: #04cb94; font-weight: 600">{{
                      studentDetail.studentWuKongBeanStatistics &&
                      studentDetail.studentWuKongBeanStatistics.pointsBalance
                    }}</span></span
                  >
                </a-popover>
                <span class="pending-task" @click="goTask"
                  >学员任务<a-icon type="right" style="font-size: 12px; margin-left: 2px" /><span
                    v-if="studentDetail.taskCount > 0"
                    :class="{ 'task-num': true, disable: !studentDetail.taskCount }"
                    >{{ studentDetail.taskCount }}</span
                  ></span
                >

                <!-- <a-dropdown
                  v-if="studentDetail.notFilledAdmissionProfile && studentDetail.notFilledAdmissionProfile.length > 0"
                >
                  <span class="ant-dropdown-link" @click="(e) => e.preventDefault()" style="color: red">
                    未填写档案 <a-icon type="down" />
                  </span>
                  <a-menu slot="overlay">
                    <a-menu-item
                      v-for="item in studentDetail.notFilledAdmissionProfile"
                      :key="item.orderNum"
                      @click="copyLink(item)"
                    >
                      {{ initOptionsType(item.subject, subjectArr) }}
                    </a-menu-item>
                  </a-menu>
                </a-dropdown> -->
              </div>
            </div>
          </div>
          <!-- 是班主任且不是管理岗 -->
          <div class="student-layer-tags-wrapper">
            <layer-tags />
          </div>
        </div>
        <div class="student-rightWrap">
          <!-- <div class="student-percentItem">
            <div style="margin-bottom: 5px">
              <a-radio-group v-model="percentSubject" @change="handleChangeSubject" size="small">
                <a-radio value="CHINESE"> 中文 </a-radio>
                <a-radio value="MATH"> 数学 </a-radio>
              </a-radio-group>
            </div>

            <div class="percent-txt">
              <a-tooltip :overlayStyle="{ maxWidth: '500px' }">
                <template slot="title"> 近30天作业完成率 </template>
                <i class="explainDetail"></i>
              </a-tooltip>
              <span class="percent-label">作业</span>
              <span class="percent-num"> {{ percentObj.homeworkCompletedRate || '-' }}</span>
            </div>
            <div class="percent-txt">
              <a-tooltip :overlayStyle="{ maxWidth: '500px' }">
                <template slot="title"> 近30天出勤率 </template>
                <i class="explainDetail"></i>
              </a-tooltip>
              <span class="percent-label">出勤</span>
              <span class="percent-num"> {{ percentObj.attendanceRate || '-' }}</span>
            </div>
          </div> -->
          <div class="student-lineItem"></div>
          <div class="student-rightItem">
            <!-- <div class="calendar-img">
              <span class="calendar-num">{{ zoneTime && zoneTime.substring(8, 10) }}</span>
              <img src="@/assets/newImage/calendar.png" />
            </div> -->
            <div class="calendar-text">
              <div class="date-txt1">{{ zoneTime && zoneTime.substring(11) }}</div>
              <div class="date-txt2">
                <span>{{ zoneTime && zoneTime.substring(0, 11) }}</span>
                <a-tooltip>
                  <template slot="title">
                    {{ studentDetail.timezoneStd }}
                  </template>
                  <span class="timezone-txt">{{ studentDetail.timezoneStd }}</span>
                </a-tooltip>
              </div>
            </div>
          </div>
          <div class="student-lineItem"></div>
          <div class="split-line mr-10"></div>
          <div class="student-rightItemSub">
            <div class="course-title">
              <span class="course-operate mr-20" @click="hrefClass"
                >学员课表<a-icon type="right" style="font-size: 12px; margin-left: 6px"
              /></span>
              <span class="course-operate" @click="hrefSchedule" style="margin-left: 18px"
                >去排课<a-icon type="right" style="font-size: 12px; margin-left: 6px"
              /></span>
            </div>
            <div class="course-content">
              <span class="course-num"
                >今日课节<span class="course-bigTxt mr-20">
                  {{ studentDetail.scheduleStatistics ? studentDetail.scheduleStatistics.todayNum : 0 }}</span
                ></span
              >
              <span class="line"></span>
              <span class="course-num1"
                >剩余课节<span class="course-bigTxt">{{
                  studentDetail.scheduleStatistics ? studentDetail.scheduleStatistics.total : 0
                }}</span></span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="student-content">
        <a-tabs :default-active-key="activeKey" @change="callback">
          <a-tab-pane key="data" tab="资料">
            <studentData
              v-if="showTab === 'data'"
              @editTimezoneStd="editTimezoneStd"
              @refresh="queryStudentDetailHead"
            ></studentData>
          </a-tab-pane>
          <a-tab-pane key="classHour" tab="课时">
            <studentClassHour v-if="showTab === 'classHour'"></studentClassHour>
          </a-tab-pane>
          <a-tab-pane key="service" tab="服务">
            <studentService v-if="showTab === 'service'"></studentService>
          </a-tab-pane>
          <a-tab-pane key="situation" tab="学情">
            <studentSituation :code="studentDetail.code" v-if="showTab === 'situation'"></studentSituation>
          </a-tab-pane>
          <!-- <a-tab-pane key="report" tab="报告">
            <studentReport v-if="showTab === 'report'"></studentReport>
          </a-tab-pane> -->
          <a-tab-pane key="study" tab="学习">
            <studentStudy v-if="showTab === 'study'"></studentStudy>
          </a-tab-pane>
          <a-tab-pane key="followUp" tab="跟进">
            <studentFollow v-if="showTab === 'followUp'"></studentFollow>
          </a-tab-pane>
          <a-tab-pane
            key="chat"
            tab="聊天"
            v-if="userInfo.roleName !== 'CLASS_ADMIN' && userInfo.roleName !== 'X_WUKONG'"
          >
            <chat-record v-if="showTab === 'chat'" :imGroupList="imGroupList"></chat-record>
          </a-tab-pane>
          <a-tab-pane key="order" tab="订单">
            <studentOrder v-if="showTab === 'order'" :studentId="studentId"></studentOrder>
          </a-tab-pane>
          <a-tab-pane key="clue" tab="线索">
            <student-clue
              v-if="showTab === 'clue' && studentProfile && studentProfile.code"
              :reseller="studentDetail.reseller"
              :studentCode="studentCode"
            ></student-clue>
          </a-tab-pane>
          <a-tab-pane key="layered" tab="动态">
            <!-- <studentLayered v-if="showTab === 'layered'"></studentLayered> -->
            <StudentLayer :uuid="studentId" v-if="showTab === 'layered'" />
          </a-tab-pane>
          <a-tab-pane key="task" tab="任务">
            <TaskList
              mode="component"
              :compFromStudentCode="studentDetail.code"
              v-if="studentDetail.code && showTab === 'task'"
            />
          </a-tab-pane>
        </a-tabs>
      </div>
      <CreateClue
        v-if="clueFlag && backDataFlag"
        :reseller="studentDetail.reseller"
        :type="clueType"
        :studentProfile="studentProfile"
        @cancel="cancelCreateClue"
      ></CreateClue>
      <!--添加任务-->
      <a-modal title="新建任务" :visible="approvalFlag" :footer="null" @cancel="cancelApproval">
        <div class="approval-wrap" @click="clickApproval">
          <div class="approval-item" :class="{ selActive: selFlag }">
            <p>分享赠课审批</p>
            <p>学生分享赠课链接确认</p>
          </div>
        </div>
      </a-modal>
      <ApproveType
        :disabled="approveTypeDisabled"
        v-if="showApprovalModal"
        @cancal="cancal"
        :studentProfile="studentProfile"
      ></ApproveType>
      <EmailEdit :visible="showEmail" @onCancel="onCancelEmail" :studentInfo="studentBasicInfo"></EmailEdit>
      <ConfirmLesson
        titleTxt="更新首课时间"
        :showInfoData="showInfoData"
        v-if="visibleModal"
        :studentInfo="studentInfo"
        @handleCancelFirstDate="handleCancelFirstDate"
        @handleOkFirstDate="handleOkFirstDate"
      ></ConfirmLesson>
    </div>
    <div class="spin-wrap" v-show="showSpin">
      <a-spin size="large" style="padding-top: 20%" />
    </div>
    <record-modal
      :taskObj="taskObj"
      :visible="visibleFollow"
      v-if="visibleFollow"
      :type="recordType"
      @closeModal="closeRecordModal"
    ></record-modal>
  </div>
</template>
<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import moment from 'moment';
import momentZe from 'moment-timezone';
import { Modal } from 'ant-design-vue';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import recordModal from '@/views/mainHome/recordModal.vue';

import {
  queryStudentDetailHead,
  updateFirstData,
  profileStatistics,
  searchOwnerMyClue,
  lookStudentDefaultMobile,
  phoneCallLog,
} from '@/api/headTeacher';
import EmailEdit from '@/views/mainHome/emailEdit';
import studentData from './studentData.vue';
import studentClassHour from './studentClassHour.vue';
import studentService from './studentService.vue';
import studentSituation from './studentSituation.vue';
import studentReport from './studentReport.vue';
import studentFollow from './studentFollow.vue';
import chatRecord from '@/views/im/chatRecord.vue';
import StudentClue from './studentClue.vue';
import CreateClue from '@/views/mainHome/createClue';
import ApproveType from '@/views/mainHome/ApproveType.vue';
import studentOrder from './studentOrder.vue';
import ConfirmLesson from '@/views/mainHome/ConfirmLesson.vue';
import studentLayered from './studentLayered.vue';
import studentStudy from './studentStudy.vue';
import { getTimezoneName } from '@/utils/geoLocation';
import { reportEvent } from '@/utils/gatherer';
import { getUserUUID } from '@/utils/user';
import StudentLayer from './components/StudentLayer.vue';
import TaskList from '../mainHome/home';

import LayerTags from './components/LayerTags.vue';

const session = storage.createStore(sessionStorage);
const token = storage.get(ACCESS_TOKEN);

export default {
  name: 'StudentDetail',
  components: {
    EmailEdit,
    studentData,
    studentClassHour,
    studentService,
    studentSituation,
    studentReport,
    studentFollow,
    chatRecord,
    StudentClue,
    CreateClue,
    ApproveType,
    studentOrder,
    ConfirmLesson,
    studentLayered,
    studentStudy,
    recordModal,
    StudentLayer,
    LayerTags,
    TaskList,
  },
  data() {
    return {
      getTimezoneName,
      showInfoData: {},
      showInfoDataChinese: {},
      showInfoDataMath: {},
      showInfoDataEnglish: {},
      studentInfoChinese: {},
      studentInfoMath: {},
      studentInfoEnglish: {},

      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      taskObj: {},
      recordType: 'COMMON',
      visibleFollow: false,
      percentObj: {},
      percentSubject: 'CHINESE',
      studentCode: '',
      newLeadVisible: false,
      showSpin: true,
      backDataFlag: false,
      rewardMethodArr: [
        { value: 'COURSE_UNIT', label: '课时' },
        { value: 'CASH', label: '现金' },
      ],
      activeKey: this.$route.query.type || 'data',
      showTab: this.$route.query.type || 'data',
      showChatFlag: false,
      processInstanceId: '',
      processInstanceIdChinese: '',
      processInstanceIdMath: '',
      processInstanceIdEnglish: '',
      visibleModal: false,
      showChineseFirstLessonBtn: false,
      showMathFirstLessonBtn: false,
      showEnglishFirstLessonBtn: false,
      approveTypeDisabled: true,
      showApprovalModal: false,
      approvalFlag: false,
      studentProfile: session.get('studentProfile'),
      clueType: '',
      selFlag: false,
      clueFlag: false,
      clueArr: [
        { label: '普通线索', value: 'INSIDE_NORMAL' },
        { label: '学员拓科', value: 'INSIDE_COPY' },
        { label: '一家多娃', value: 'INSIDE_MULTI' },
      ],
      userInfo: {},
      imGroupList: [],
      zoomMoble: '',
      showEmail: false,
      studentId: '',
      studentDetail: {},
      studentInfo: {},
      studentBasicInfo: {},

      currentStudentTime: null,
      zoneTime: null,
      mathFirstLessonDate: '',
      chineseFirstLessonDate: '',
    };
  },
  computed: {},
  methods: {
    hrefWuKongDouDetail() {
      this.$router.push({ path: '/wuKongDouDetail', query: { studentCode: this.studentDetail.code } });
    },
    // 复制链接
    copyLink(item) {
      const url = `${process.env.VUE_APP_PROFILE_URL}?orderNum=${item.orderNum}&source=CA_STUDIO`; // 判断中文链接还是数学链接
      const input = document.createElement('input');
      input.value = url;
      document.body.appendChild(input);
      input.select();
      document.execCommand('Copy');
      document.body.removeChild(input);
      this.$message.success('已复制链接');
    },
    goLostRefundStudent() {
      const routeUrl = this.$router.resolve({
        path: '/administration/lostRefundStudent',
        query: {
          studentId: this.studentProfile.uuid,
          showAll: true,
        },
      });
      window.open(routeUrl.href, '_blank');
    },
    closeRecordModal() {
      this.visibleFollow = false;
    },
    openRecordsModal(item) {
      this.recordType = 'COMMON';
      this.taskObj = {
        studentId: this.studentProfile.uuid,
        followType: 'COMMON',
        uuid: item.uuid,
        studentName: this.studentProfile.fullName,
        firstCourseDate: this.firstLesson,
        taskId: item.taskId ? item.taskId : '',
        taskName: item.taskName ? item.taskName : '',
        processInstanceId: item.processInstanceId ? item.processInstanceId : '',
      };
      this.visibleFollow = true;
    },
    handleChangeSubject(val) {
      this.queryProfileStatistics();
    },
    // 获取作业出勤率，完成率
    queryProfileStatistics() {
      const params = {
        studentId: this.studentId,
        subject: this.percentSubject,
      };
      profileStatistics(params).then((res) => {
        this.percentObj = res.data.content;
      });
    },
    handleMenuClick(e) {
      if (e.key === 'addTask') {
        this.addTask();
      } else if (e.key === 'CHINESE' || e.key === 'MATH' || e.key === 'ENGLISH') {
        const url = `${process.env.VUE_APP_Timetable_URL}?token=${token}&subject=${e.key}&name=${this.studentDetail.fullName}&phone=${this.studentDetail.mobile}&timezone=${this.studentDetail.timezoneStd}&locale=zh`;
        window.open(url);
      }
    },
    hrefSchedule() {
      //  this.$router.push({ name: 'toScheduleClasses', params: {studentDetail:this.studentDetail} });
      this.$router.push({ path: '/toScheduleClasses' });
    },
    // 编辑了时区，更新头部
    editTimezoneStd() {
      this.queryStudentDetailHead();
    },
    // 评论，转交，抄送操作成功，回到详情弹窗
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });

      return name;
    },
    goTask() {
      this.$router.push({ path: '/mainHome', query: { studentCode: this.studentDetail.code } });
    },
    momentZe,
    hrefChat() {
      // 去fullName,可能有两个群
      if (this.studentDetail.imGroupDto === null) {
        this.$message.warning('暂无对应的聊天群');
      } else {
        this.$store.commit('setGroupName', this.studentDetail.imGroupDto.groupName);
        this.$store.commit('setGroupIdRoute', this.studentDetail.imGroupDto.groupId);
        this.$router.push({ path: '/headTeacher' });
      }
    },
    handleCancelFirstDate() {
      this.visibleModal = false;
    },
    handleOkFirstDate(obj) {
      // 更新首课时间
      const params = {
        processInstanceId: this.processInstanceId,
        studentId: this.studentId,
        subject: this.studentInfo.courseSubject,
        studentCode: this.studentDetail.code,
        firstCourseDate: obj.firstLesson,
        firstCourseScheduleId: obj.firstCourseScheduleId,
        firstFromClassId: obj.firstFromClassId,
        remark: obj.remark,
        firstCourseScheduleName: obj.firstCourseScheduleName,
        firstFromClassName: obj.firstFromClassName,
        firstCourseEndDate: obj.firstCourseEndDate,
      };
      updateFirstData(params).then((res) => {
        this.$message.success('更新首课时间成功');
        this.visibleModal = false;
        this._queryStudentDetail();
      });
    },
    // 更新学生首课时间
    _updateFirstLesson(val) {
      if (val === 'CHINESE') {
        this.studentInfo = { ...this.studentInfoChinese };
        this.showInfoData = { ...this.showInfoDataChinese };
        this.processInstanceId = this.processInstanceIdChinese;
      } else if (val === 'MATH') {
        this.studentInfo = { ...this.studentInfoMath };
        this.showInfoData = { ...this.showInfoDataMath };
        this.processInstanceId = this.processInstanceIdMath;
      } else if (val === 'ENGLISH') {
        this.studentInfo = { ...this.studentInfoEnglish };
        this.showInfoData = { ...this.showInfoDataEnglish };
        this.processInstanceId = this.processInstanceIdEnglish;
      }

      this.visibleModal = true;
    },
    hrefClass() {
      this.$router.push({ path: '/calendar', query: { uuid: this.$route.query.uuid || this.studentProfile.uuid } });
    },
    initTime() {
      if (this.studentDetail?.whenCreated) {
        this.currentStudentTime = setInterval(() => {
          const m = this.momentZe().tz(this.studentDetail.timezoneStd);
          const time = m.format('YYYY-MM-DD HH:mm:ss');
          this.zoneTime = time;
        }, 1000);
      } else {
        clearInterval(this.currentStudentTime);
      }
    },
    handleSharePoster() {
      reportEvent({
        data: {
          Code: this.studentDetail.code,
          _event: 'CAstudio_ShareClick',
          User_id: getUserUUID(),
        },
      });
      this.$router.push({ path: '/studentposter' });
    },
    cancal(val) {
      if (val === 'submit') {
        this.showApprovalModal = false;
        this.approvalFlag = false;
      } else {
        this.showApprovalModal = false;
      }
    },
    cancelApproval() {
      this.approvalFlag = false;
    },
    clickApproval() {
      this.selFlag = true;
      this.showApprovalModal = true;
    },
    addTask() {
      this.approvalFlag = true;
    },
    cancelCreateClue() {
      this.clueFlag = false;
    },
    searchOwnerMyClue() {
      const params = {
        stuNumber: this.studentProfile.code,
        subject: this.userInfo.subject === 'MATH' ? 'Math' : 'Chinese',
        type: this.clueType,
      };
      searchOwnerMyClue(params).then((res) => {
        const data = res.data.content;

        if (data !== null) {
          // if (data.mobile.indexOf('+86') > -1) {
          //   this.$message.warning('原+86线索不允许拓科');
          // } else {
          //   this.clueFlag = true;
          // }
          this.clueFlag = true;
        }
      });
    },
    createClue(type) {
      this.newLeadVisible = false;
      this.clueType = type;

      // 多娃跳分享海报
      if (type === 'INSIDE_MULTI') {
        this.$router.push({ path: '/studentposter', query: { type: 'TRACK_POSTER' } });
      } else if (type === 'INSIDE_COPY') {
        const payTime = moment('2023-08-01 00:00:00');
        const dayHours = moment().diff(payTime, 'days');
        if (dayHours < 30) {
          this.$message.error(`当前学员首课成交${dayHours}天，不可创建拓课线索`);
          return;
        }
        // 学员拓科，判断有没有+86的线索
        this.searchOwnerMyClue();
      } else {
        this.clueFlag = true;
      }
    },
    openEmail() {
      this.showEmail = true;
    },
    onCancelEmail() {
      this.showEmail = false;
    },

    initMobile(data) {
      let str = data.split(' ')[1].toString().replace(/ /g, '');
      const len = str.length;
      switch (true) {
        case len > 11:
          str = `${str.substr(0, 3)} ${str.substr(3, 4)} ${str.substr(7, 4)}`;
          break;
        case len > 7:
          str = `${str.substr(0, 3)} ${str.substr(3, 4)} ${str.substr(7)}`;
          break;
        case len > 3:
          str = `${str.substr(0, 3)} ${str.substr(3)}`;
          break;
        default:
          this.value = str;
      }
      const [countryCode, phone] = data.split(' ');

      phoneCallLog({
        phone,
        countryCode,
      });

      this.zoomMoble = `zoomphonecall://${data.split(' ')[0]}${str}?cat=seccall`;

      window.location.href = this.zoomMoble;
    },
    async zoomMobleFun() {
      const { localTime } = this.studentDetail;

      const h = moment(localTime).get('hour');

      if (h < 9 || h >= 21) {
        await new Promise((resolve, reject) => {
          Modal.confirm({
            title: '温馨提示',
            content: `该客户当地时间为${moment(localTime).format(
              'YYYY-MM-DD HH:mm:ss',
            )}，可能已经休息咯，你确认继续拨打电话？`,
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk: () => {
              resolve();
            },
            onCancel() {
              reject();
            },
          });
        });
      }

      // 先查默认电话
      const params = {
        contactType: 'MOBILE',
        studentId: this.studentId,
      };
      lookStudentDefaultMobile(params).then((res) => {
        this.initMobile(res.data.content);
      });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },

    // 查询学生顶部详情
    queryStudentDetailHead() {
      const params = {
        studentId: this.studentId || this.$route.query.uuid,
      };
      queryStudentDetailHead(params).then((res) => {
        this.showSpin = false;
        this.studentDetail = res.data.content;
        // 判断是中文班主任，还是数学
        if (
          this.studentDetail.classAdmin &&
          this.studentDetail.classAdmin.CHINESE &&
          this.studentDetail.classAdmin.CHINESE.accountId === this.userInfo.userID
        ) {
          // 中文班主任
          this.percentSubject = 'CHINESE';
        }
        if (
          this.studentDetail.classAdmin &&
          this.studentDetail.classAdmin.MATH &&
          this.studentDetail.classAdmin.MATH.accountId === this.userInfo.userID
        ) {
          // 中文班主任
          this.percentSubject = 'MATH';
        }
        // 获取作业率，出勤率

        this.queryProfileStatistics();
        document.title = this.studentDetail.fullName;
        // 判断聊天按钮是否展示
        this.studentDetail.owners.forEach((item) => {
          if (item === this.userInfo.userID) {
            this.showChatFlag = true;
          }
        });
        const { fullName, code, timezoneStd, timezone, email, classAdmin, headImageUrl, mobile, country, englishName } =
          this.studentDetail;

        const conversation_student = {
          fullName,
          englishName,
          code,
          uuid: this.studentId,
          timezoneStd,
          timezone,
          email,
          classAdmin,
          headImageUrl,
          mobile,
          country,
          percentSubject: this.percentSubject, // 判断是中文班主任还是数学班主任
        };
        // 记录选中学生信息
        session.set('studentProfile', conversation_student);
        this.studentProfile = conversation_student;
        this.backDataFlag = true;
        // 群信息
        this.imGroupList = this.studentDetail.imGroupList;

        // 学生一些基本信息
        this.studentBasicInfo = {
          fullName: this.studentDetail.fullName,
          email: this.studentDetail.email,
          code: this.studentDetail.code,
          uuid: this.studentId,
          englishName: this.studentDetail.englishName,
        };

        this.initTime();

        // 判断更改首课时间
        if (
          this.studentDetail.chineseFirstLessonDate &&
          this.studentDetail.classAdmin.CHINESE.accountId === this.userInfo.userID
        ) {
          // 判断首课时间
          if (this.studentDetail.data.firstCourse_CHINESE.task) {
            this.studentInfoChinese = {
              studentName: this.studentDetail.fullName,
              studentId: this.studentId,
              courseType: 'STANDARD',
              courseSubject: 'CHINESE',
            };

            this.showChineseFirstLessonBtn = true;
            this.showInfoDataChinese = {
              firstFromClassId: this.studentDetail.data.firstCourse_CHINESE.fromClassId,
              firstCourseScheduleId: this.studentDetail.data.firstCourse_CHINESE.courseScheduleId,
              currentFirstLessonDate: this.studentDetail.chineseFirstLessonDate,
            };
            this.processInstanceIdChinese = this.studentDetail.data.firstCourse_CHINESE.task.processInstanceId;
          } else {
            this.showChineseFirstLessonBtn = false;
          }
        }
        if (
          this.studentDetail.mathFirstLessonDate &&
          this.studentDetail.classAdmin.MATH.accountId === this.userInfo.userID
        ) {
          // 有首课任务才能去更新首课时间
          if (this.studentDetail.data.firstCourse_MATH.task) {
            this.showInfoDataMath = {
              firstFromClassId: this.studentDetail.data.firstCourse_MATH.fromClassId,
              firstCourseScheduleId: this.studentDetail.data.firstCourse_MATH.courseScheduleId,
              currentFirstLessonDate: this.studentDetail.mathFirstLessonDate,
            };
            // 判断更改首课时间
            this.studentInfoMath = {
              studentName: this.studentDetail.fullName,
              studentId: this.studentId,
              courseType: 'STANDARD',
              courseSubject: 'MATH',
            };

            this.showMathFirstLessonBtn = true;
            this.processInstanceIdMath = this.studentDetail.data.firstCourse_MATH.task.processInstanceId;
          } else {
            this.showMathFirstLessonBtn = false;
          }
        }

        // 更新英文首课
        if (
          this.studentDetail.englishFirstLessonDate &&
          this.studentDetail.classAdmin.ENGLISH.accountId === this.userInfo.userID
        ) {
          // 有首课任务才能去更新首课时间
          if (this.studentDetail.data.firstCourse_ENGLISH.task) {
            this.showInfoDataEnglish = {
              firstFromClassId: this.studentDetail.data.firstCourse_ENGLISH.fromClassId,
              firstCourseScheduleId: this.studentDetail.data.firstCourse_ENGLISH.courseScheduleId,
              currentFirstLessonDate: this.studentDetail.englishFirstLessonDate,
            };
            // 判断更改首课时间
            this.studentInfoEnglish = {
              studentName: this.studentDetail.fullName,
              studentId: this.studentId,
              courseType: 'STANDARD',
              courseSubject: 'ENGLISH',
            };

            this.showEnglishFirstLessonBtn = true;

            this.processInstanceIdEnglish = this.studentDetail.data.firstCourse_ENGLISH.task.processInstanceId;
          } else {
            this.showEnglishFirstLessonBtn = false;
          }
        }
      });
    },

    callback(key) {
      this.showTab = key;
    },
  },
  mounted() {
    this.studentId = this.$route.query.uuid || this.studentProfile.uuid; // 从筋斗云新建订单过来
    const obj = storage.get('userInfo');
    // 从任务里面关联线索来
    this.studentCode = this.$route.query.studentCode || '';
    this.userInfo = obj;
    // 添加太平洋时区
    momentZe.tz.add(
      'US/Pacific-New|PST PDT PWT PPT MST|80 70 70 70 70|0102301010101010101010101010101010101010101010101010101014|-25TO0 1in0 UGp0 8x10 iy0 3NB0 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 ML0|12e3',
    );

    this.queryStudentDetailHead();
  },
  // 从订单详情，线索详情进入这个界面，选中对应的tab
  // beforeRouteEnter(to, from, next) {
  //     console.log(from)
  //   next(vm => {
  //     if (from.name === 'OrderDetail') {
  //         console.log(111)
  //       vm.activeKey ='order';
  //       vm.showTab='order'
  //       return;
  //     }else if(from.name == 'clueDetail'){
  //        vm.activeKey ='clue';
  //         vm.showTab='clue'
  //       return;
  //     }else {
  //       return;
  //     }
  //   })
  // },
};
</script>

<style lang="less" scoped>
.douNum {
  font-weight: 600;
}
.douItem {
  margin-bottom: 4px;
}
.descriptions-wrap {
  margin-bottom: 10px;
}

.ambassadorPopover .ant-popover-inner {
  border-radius: 18px;
}
</style>
<style lang="less" scoped>
// /deep/.student-wrap .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane {
//   min-height: calc(100vh - 130px);
// }

.student-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/deep/ .ant-btn-primary {
  box-shadow: none;
}
/deep/ .ant-tabs-nav .ant-tabs-tab {
  font-weight: 600;
}
.classPopover {
  .ant-popover-inner-content {
    width: 150px;
    padding: 0 16px;
  }
}
.clue-wrap {
  padding: 0;
  margin-bottom: 0;
  li {
    padding: 6px;
    cursor: pointer;
    font-size: 14px;
  }
  li:hover {
    background: rgba(67, 209, 134, 0.1);
  }
  .active {
    background: #e5f5ff;
    color: #009cff;
  }
}
/deep/ .ant-descriptions-title {
  &::before {
    content: '';
    display: block;
    float: left;
    margin: 4px 6px 0 0;
    width: 3px;
    height: 16px;
    background-color: #41cc83;
    border-radius: 0px 2px 2px 0px;
  }
}
.student-top {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
}
.student-leftWrap {
  flex: 1.5;
  margin-right: 16px;
  border-radius: 4px;
  background: #fff;
  padding: 18px 20px;
}
.student-leftWrap-info {
  display: flex;
  overflow: hidden;
  position: relative;
}
.student-layer-tags-wrapper {
  margin-top: 16px;
}

.student-rightWrap {
  background: #fff;
  display: flex;
  padding: 16px 12px;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  overflow: auto;
  /deep/ span.ant-radio + * {
    padding-right: 8px;
    padding-left: 0px;
  }
}
.student-content-info {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.student-content {
  width: 100%;
  padding: 18px 20px 0;
  background-color: #fff;
  flex: 1;
}

.warning-pic {
  width: 12px;
  height: 12px;
}
.student-portImg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.student-portrait {
  cursor: pointer;
}
.operator-icon {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.student-name {
  font-weight: 600;
  font-size: 20px;
  color: #333;
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.timezone-txt {
  font-weight: 600;

  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  padding-left: 12px;
}
.operator-btnWrap {
  margin-left: auto;
}
.top-infowrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
.top-info {
  align-items: center;
  display: flex;
}
.operator-btn {
  color: #1f2d3d;
  font-size: 13px;
  padding: 5px 8px;
  margin-left: 10px;
  border: 1px solid rgba(31, 45, 61, 0.18);
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    border: 1px solid #00bf8a;
    color: #00bf8a;
  }
}
.btn-left {
  margin-left: 10px;
}
.remaining-title {
  color: rgba(0, 0, 0, 0.65);
  margin: 0 8px 0 7px;
}
.bot-infowrap {
  margin-top: 12px;
  font-size: 13px;
  display: flex;
  align-items: center;
}
.remaining-text {
  color: rgba(0, 0, 0, 0.4);
  margin-right: 12px;
}
.warning {
  color: #ff4d4f;
}

.remaining-num {
  margin: 0 5px;
  color: rgba(0, 0, 0, 0.85);
}
.line {
  width: 1px;
  height: 14px;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 0px 2px 2px 0px;
}
.student-rightItem {
  flex: 1;
  display: flex;
  align-items: center;
}
.student-percentItem {
  min-width: 145px;
}
.selActive {
  background: #41cc83;
  color: #fff;
}
.approval-wrap {
  display: flex;
}
.approval-item {
  padding: 15px 15px;
  border: 1px solid #c3c0c0;
  cursor: pointer;
  border-radius: 10px;
}
.approval-txt {
  font-size: 14px;
}
.calendar-img {
  width: 64px;
  height: 64px;
  position: relative;
  margin-right: 10px;
  img {
    width: 100%;
    height: 100%;
  }
  .calendar-num {
    position: absolute;
    left: 50%;
    top: 26px;
    transform: translate(-50%, 0);
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #ffffff;
    text-shadow: 0px 1.08333px 3.25px #00f3c2;
  }
}
.date-txt1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #1f2d3d;
  margin-bottom: 4px;
}
.date-txt2 {
  font-size: 12px;
  line-height: 20px;
  color: rgba(31, 45, 61, 0.4);
  margin-left: 4px;
}
.student-lineItem {
  width: 8px;
  height: 80px;
  background-size: 100%;
  margin: 0 5px;
}
.course-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 10px;
}
.course-operate {
  cursor: pointer;
  &:hover {
    color: #04cb94;
  }
}

.student-rightItemSub {
  width: 200px;
}
.course-content {
  font-size: 12px;
  line-height: 20px;
  color: rgba(31, 45, 61, 0.8);
  display: flex;
  gap: 8px;
  align-items: center;
}
.course-num1 {
}
.course-bigTxt {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1f2d3d;
  margin-left: 8px;
}
.pending-task {
  margin-left: 20px;
  cursor: pointer;
  &:hover {
    color: #04cb94;
  }
}
.task-num {
  background-color: #ff4d4f;
  color: #fff;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 50%;
  margin-left: 2px;
  &.disable {
    background: rgba(0, 0, 0, 0.4);
  }
}
.reseller-img {
  width: 28px;
  height: 17px;
  position: absolute;
  top: 16px;
  left: 63px;
}
.promotionAmbassador {
  background: #ffbf00;
  color: #fff;
  font-size: 13px;
  padding: 4px 6px;
  margin-left: 10px;
  border-radius: 3px;
}
.ambassador {
  width: 9px;
  height: 9px;
}
.ambassador-item {
  padding: 0 16px 10px;
}
.ambassador-label {
  width: 120px;
  color: #999999;
  font-size: 14px;
  display: inline-block;
}

.student-start {
  width: 14px;
  height: 14px;
  margin-right: 2px;
  margin-top: -3px;
}
.icon_finish {
  width: 14px;
  height: 14px;
  margin-right: 3px;
}
.tag-label {
  background: rgba(67, 209, 134, 0.06);
  border-radius: 2px;
  padding: 3px 5px;

  margin-left: 20px;
}
.ant-dropdown-link {
  margin-left: 20px;
  cursor: pointer;
}
.spin-wrap {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: calc(100vh - 64px);
  background-color: rgba(255, 255, 255, 0.7);
  text-align: center;
  z-index: 100;
}
.explainDetail {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url('~@/assets/explain.png') no-repeat center;
  background-size: 100%;
  margin-left: 4px;
  cursor: pointer;
}
.percent-txt {
  align-items: center;
  display: flex;
  margin-bottom: 5px;
}
.percent-label {
  margin: 0 5px 0 5px;
}
.percent-num {
  color: #1f2d3d;
  font-weight: bold;
}
.split-line {
  width: 10px;
  height: 100px;
  background: url('~@/assets/newImage/line.png');
  background-size: contain;
}
</style>
